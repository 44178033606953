import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { del, get, post, put } from "../../api/apiClient";
import { message } from "antd";
import { DroppedItem } from "../../components/CreateForm/FormBuilder";

// Define initial state
export interface FormData {
  _id: string;
  formTitle: string;
  formLogo: string;
  formDescription: string;
  formBuilderData: DroppedItem[];
  status: "active" | "inactive";
}

interface FormListState {
  data: FormData[];
  loading: boolean;
  currentPage: number;
  pageSize: number;
  total: number;
}

const initialState: FormListState = {
  data: [],
  loading: false,
  currentPage: 1,
  pageSize: 5,
  total: 0,
};

// Create async thunk for fetching form data
export const fetchFormData = createAsyncThunk(
  "formList/fetchFormData",
  async ({
    page,
    limit,
    search,
  }: {
    page: number;
    limit: number;
    search: string;
  }) => {
    const response = await get(
      `/form-builder/list/paginate?sortKey=createdAt&sortDirection=desc&page=${page}&limit=${limit}&search=${search}`
    );
    return response.data;
  }
);

export const fetchFormTemplateData = createAsyncThunk(
  "formList/fetchFormTemplateData",
  async (search?: string) => {
    const response = await get(
      `/form-builder/template-list?search=${search ?? ""}`
    );
    return response.data;
  }
);

export const getForm = createAsyncThunk(
  "formList/fetchFormData",
  async (id: string) => {
    try {
      const response = await get(`/form-builder/edit/${id}`);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const createForm = createAsyncThunk(
  "formList/createForm",
  async ({
    title,
    description,
    useAsTemplate,
    image,
    formBuilderData,
  }: {
    title: string;
    description: string;
    useAsTemplate: boolean;
    image: string;
    formBuilderData?: DroppedItem[];
  }) => {
    try {
      const response = await post(`/form-builder/add`, {
        formTitle: title?.trim(),
        formDescription: description,
        isTemplate: useAsTemplate,
        formLogo: image,
        formBuilderData: formBuilderData,
      });
      return response.data;
    } catch (error) {
      message.error("Failed to create form.");
    }
  }
);

export const updateForm = createAsyncThunk(
  "formList/updateForm",
  async ({
    id,
    title,
    description,
    status,
    formBuilderData,
  }: {
    id: string;
    title?: string;
    description?: string;
    status?: "active" | "inactive";
    formBuilderData?: DroppedItem[];
  }) => {
    try {
      const response = await put(`/form-builder/update/${id}`, {
        formTitle: title,
        formDescription: description,
        status,
        formBuilderData,
      });
      return response.data;
    } catch (error) {
      message.error("Failed to update form.");
    }
  }
);

export const deleteForm = createAsyncThunk(
  "formList/deleteForm",
  async (id: string) => {
    try {
      const response = await del(`/form-builder/form-delete/${id}`);
      return response.data;
    } catch (error) {
      message.error("Failed to delete form.");
    }
  }
);

// Create the slice
const formListSlice = createSlice({
  name: "formList",
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      const { id, status } = action.payload;
      const form = state.data.find((item) => item._id === id);
      if (form) {
        form.status = status;
      }
    },
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFormData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFormData.fulfilled, (state, action: any) => {
        state.loading = false;
        state.data = action.payload?.docs ?? [];
        state.total = action.payload?.total ?? 0;
      })
      .addCase(fetchFormData.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { updateStatus, setPage, setPageSize } = formListSlice.actions;

export default formListSlice.reducer;
