import "./style.css";
import { Dropdown, Layout, MenuProps } from "antd";
import ProfileImg from "../../assets/profilephoto/profileimg.jpg";
import Logo from "../../assets/images/companylogo.png";
import { handleLogoutThunkAsync } from "../../store/slices/authSlice";
import { useAppDispatch } from "../../store/store";
const { Header } = Layout;

const HeaderCmp = () => {
  const dispatch = useAppDispatch();

  const onLogout = () => {
    dispatch(handleLogoutThunkAsync());
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <div onClick={onLogout}>Logout</div>,
    },
  ];

  return (
    <Header className="header-container">
      <div className="header-child-div">
        <div className="header-logosection">
          <img src={Logo} alt="Logo" className="header-logo" />
          {/* <span className="header-logotext">
            pro<span className="header-logotext-blue">O</span>pio
          </span> */}
        </div>
        <Dropdown menu={{ items }} placement="bottomRight">
          <div className="header-profile-section">
            <img
              src={ProfileImg}
              alt="Profile"
              className="header-profile-icon"
            />
          </div>
        </Dropdown>
      </div>
    </Header>
  );
};

export default HeaderCmp;
