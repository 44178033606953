import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const PrivateRoute = () => {
  const { token } = useSelector((state: RootState) => state.auth);

  if (!token) {
    // If no token (i.e., not authenticated), redirect to the login page
    return <Navigate to="/login" />;
  }

  return <Outlet />; // Allow access to the child routes if authenticated
};

export default PrivateRoute;
