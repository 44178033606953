import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { clearUser, setUser, User } from "./userSlice";
import { get, post } from "../../api/apiClient";
import { ApiResponse } from "../../types/types";
import { message } from "antd";

// Define the initial state for auth
interface AuthState {
  token: string | null;
}

const initialState: AuthState = {
  token: null,
};

interface LoginResponse {
  success: boolean;
  token: string;
  data: User;
}

export const handleLoginThunkAsync =
  ({ email, password }: { email: string; password: string }) =>
  async (dispatch: Dispatch) => {
    try {
      const response: ApiResponse<LoginResponse> = await post("/user/login", {
        email,
        password,
      });

      if (response?.data?.success) {
        dispatch(setToken(response?.data?.token));
        dispatch(setUser(response?.data?.data));
      } else {
        console.error("Login failed:", response.message);
        message.error("Login failed. Please try again.");
      }
    } catch (error) {
      console.error("Login API error:", error);
      message.error("Login failed. Please try again.");
    }
  };

export const handleForgotPasswordThunkAsync =
  ({ email }: { email: string }) =>
  async () => {
    try {
      const response: any = await post("/user/forgotpassword", {
        email,
      });

      if (response?.data?.success) {
        message.success("Forgot password link sent. check your email.");
      }
    } catch (error: any) {
      console.error("Forgot password API error:", error);
      message.error(
        error?.response?.data?.errors?.[0]?.msg ??
          "Forgot password failed. Please try again."
      );
    }
  };

export const handleResetPasswordThunkAsync =
  ({ token, password }: { token: string; password: string }) =>
  async () => {
    try {
      const response: any = await post("/user/resetpassword", {
        token,
        password,
        passwordConfirm: password,
      });

      if (response?.data?.success) {
        message.success("Reset password successfully.");
      }
    } catch (error: any) {
      console.error("Reset password API error:", error);
      message.error(
        error?.response?.data?.errors?.[0]?.msg ??
          "Reset password failed. Please try again."
      );
    }
  };

export const handleLogoutThunkAsync = () => async (dispatch: Dispatch) => {
  try {
    await get("/user/logout");
  } catch (error) {
    console.error("Logout API error:", error);
    message.error("Logout failed. Please try again.");
  } finally {
    dispatch(clearToken());
    dispatch(clearUser());
  }
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Action to set the login token
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
      localStorage.setItem("token", `${action.payload}`);
    },
    // Action to clear the token (e.g., on logout)
    clearToken: (state) => {
      state.token = null;
      localStorage.removeItem("token");
    },
  },
});

// Export the actions to dispatch
export const { setToken, clearToken } = authSlice.actions;

// Export the reducer to be added to the store
export default authSlice.reducer;
