import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

// Base URL for API
const BASE_URL = process.env.REACT_APP_API_URL || "https://ulgapi.ospbiz.com";

// Create an axios instance
const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Function to add the token to the headers if available
const addAuthToken = (config: AxiosRequestConfig) => {
  const token = localStorage.getItem("token");

  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };
  }
  return config;
};

// Intercept every request to add the Authorization header
apiClient.interceptors.request.use(addAuthToken as any, (error) =>
  Promise.reject(error)
);

// Generic GET request
export const get = async <T>(
  url: string,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  return apiClient.get<T>(url, config);
};

// Generic POST request
export const post = async <T>(
  url: string,
  body: any,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  return apiClient.post<T>(url, body, config);
};

// Generic PUT request
export const put = async <T>(
  url: string,
  body: any,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  return apiClient.put<T>(url, body, config);
};

// Generic DELETE request
export const del = async <T>(
  url: string,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  return apiClient.delete<T>(url, config);
};
