import React, { Dispatch, SetStateAction, useState } from "react";
import { useDrop } from "react-dnd";
import {
  Typography,
  Card,
  Input,
  DatePicker,
  ColorPicker,
  InputNumber,
  Radio,
  Select,
  Switch,
  TimePicker,
  Checkbox,
  Button,
  Upload,
  message,
} from "antd";
import { IoIosMove } from "react-icons/io";
import { FaSave } from "react-icons/fa";
import { LuEye } from "react-icons/lu";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";

import "./style.css";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../store/store";
import { updateForm } from "../../../store/slices/formListSlice";
import { DroppedItem } from ".";
import FormPreview from "./FormPreview";

const { Option } = Select;
const { Text } = Typography;
const CheckboxGroup = Checkbox.Group;

const FormItem = ({
  label,
  component,
  className,
}: {
  label: string;
  component: React.ReactNode;
  className?: string;
}) => (
  <div className={className}>
    <label className="form-label">{label}</label>
    {component}
  </div>
);

interface DropZoneProps {
  formId: string;
  formName: string;
  formLogo: string;
  onDrop: (item: DroppedItem) => void;
  droppedItems: DroppedItem[];
  onDelete: (id: string) => void;
  onMoveUp: (id: string) => void;
  onMoveDown: (id: string) => void;
  selectedItemId: string | null;
  setSelectedItemId: Dispatch<SetStateAction<string | null>>;
}

const DropZone = ({
  formId,
  formName,
  formLogo,
  onDrop,
  droppedItems,
  onDelete,
  onMoveDown,
  onMoveUp,
  selectedItemId,
  setSelectedItemId,
}: DropZoneProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: [
      "text",
      "textarea",
      "datepicker",
      "color",
      "select",
      "number",
      "radio",
      "switch",
      "timePicker",
      "checkbox",
      "checkboxGroup",
      "uploadFile",
      "email",
    ],
    drop: (item: unknown) => {
      const droppedItem = item as DroppedItem;
      onDrop(droppedItem);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));
  const [loading, setLoading] = useState<boolean>(false);
  const [isPreviewModal, setIsPreviewModal] = useState<boolean>(false);

  const backgroundColor = isOver ? "#e0e0e0" : canDrop ? "#f9f9f9" : "#fff";

  const renderField = (item: DroppedItem, index: number) => {
    const isSelected = selectedItemId === item.id;
    const itemStyle: React.CSSProperties = {
      backgroundColor: isSelected ? "#f5f7fe" : "transparent",
      padding: isSelected ? "20px" : "0px",
      paddingBottom: isSelected ? "35px" : "0px",
      position: "relative",
      borderRadius: "8px",
      transition: "background-color 0.3s ease",
    };

    return (
      <div
        key={item.id}
        className="input-field-container"
        style={itemStyle}
        onClick={() => setSelectedItemId(item.id)}
      >
        {renderComponentByType(item)}
        {isSelected && (
          <div className="selected-items-buttons-container">
            <Button.Group>
              <Button
                className="selected-items-buttons"
                icon={
                  <RiDeleteBinLine className="selected-items-buttons-delete-icon" />
                }
                onClick={() => onDelete(item.id)}
              />
              <Button
                className="selected-items-buttons"
                icon={
                  <AiOutlineArrowUp className="selected-items-buttons-arrow-icon" />
                }
                onClick={() => onMoveUp(item.id)}
                disabled={index === 0}
              />
              <Button
                className="selected-items-buttons"
                icon={
                  <AiOutlineArrowDown className="selected-items-buttons-arrow-icon" />
                }
                onClick={() => onMoveDown(item.id)}
                disabled={index === droppedItems.length - 1}
              />
            </Button.Group>
          </div>
        )}
      </div>
    );
  };

  const renderComponentByType = (item: DroppedItem, commonProps?: any) => {
    switch (item.type) {
      case "text":
        return (
          <FormItem
            {...commonProps}
            label={item.inputTitle}
            component={
              <Input
                maxLength={item?.maxLength}
                minLength={item?.minLength}
                placeholder={item.inputPlaceholder}
              />
            }
          />
        );
      case "email":
        return (
          <FormItem
            {...commonProps}
            label={item.inputTitle}
            component={
              <Input
                maxLength={item?.maxLength}
                minLength={item?.minLength}
                placeholder={item.inputPlaceholder}
                type="email"
              />
            }
          />
        );
      case "textarea":
        return (
          <FormItem
            {...commonProps}
            label={item.inputTitle}
            component={
              <Input.TextArea
                maxLength={item?.maxLength}
                minLength={item?.minLength}
                placeholder={item.inputPlaceholder}
              />
            }
          />
        );
      case "datepicker":
        return (
          <FormItem
            {...commonProps}
            label={item.inputTitle}
            component={<DatePicker className="form-item" />}
          />
        );
      case "color":
        return (
          <FormItem
            {...commonProps}
            label={item.inputTitle}
            component={<ColorPicker />}
          />
        );
      case "select":
        return (
          <FormItem
            {...commonProps}
            label={item.inputTitle}
            component={
              <Select placeholder={item.inputPlaceholder} className="form-item">
                {item?.options?.map((e) => (
                  <Option value={e.value}>{e.label}</Option>
                ))}
              </Select>
            }
          />
        );
      case "number":
        return (
          <FormItem
            {...commonProps}
            label={item.inputTitle}
            component={
              <InputNumber
                type="number"
                placeholder={item.inputPlaceholder}
                className="form-item"
              />
            }
          />
        );
      case "radio":
        return (
          <FormItem
            {...commonProps}
            label={item.inputTitle}
            component={
              <Radio.Group>
                <Radio value={1}>A</Radio>
                <Radio value={2}>B</Radio>
                <Radio value={3}>C</Radio>
                <Radio value={4}>D</Radio>
              </Radio.Group>
            }
          />
        );
      case "switch":
        return (
          <FormItem
            {...commonProps}
            label={item.inputTitle}
            component={<Switch />}
          />
        );
      case "timePicker":
        return (
          <FormItem
            {...commonProps}
            label={item.inputTitle}
            component={<TimePicker className="form-item" />}
          />
        );
      case "checkbox":
        return (
          <FormItem
            {...commonProps}
            label={item.inputTitle}
            component={<Checkbox>{item.label}</Checkbox>}
          />
        );
      case "checkboxGroup":
        return (
          <FormItem
            {...commonProps}
            label={item.inputTitle}
            component={<CheckboxGroup options={item?.options ?? []} />}
          />
        );
      case "uploadFile":
        return (
          <FormItem
            {...commonProps}
            label={item.inputTitle}
            component={
              <Upload>
                <Button>{item.inputPlaceholder}</Button>
              </Upload>
            }
          />
        );
      default:
        return null;
    }
  };

  const onCancel = () => {
    navigate("/forms", { replace: true });
  };

  const onSave = async () => {
    setLoading(true);

    try {
      const response: any = await dispatch(
        updateForm({ id: formId, formBuilderData: droppedItems })
      );
      if (
        response.type.includes("fulfilled") &&
        response.payload?.status === "success"
      ) {
        message.success("Form updated successfully!");
      } else {
        throw new Error();
      }
    } catch (error) {
      message.error("Failed to update form.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      ref={drop}
      style={{
        backgroundColor,
      }}
      className={`drop-zone-container ${
        droppedItems.length === 0 ? "dropZoneForEmpty-parent" : ""
      }`}
    >
      <center>
        <img
          src={formLogo}
          alt="formLogo"
          width="150px"
          height="150px"
          style={{ objectFit: "contain" }}
        />
        <h3 className="drop-zone-container-title">{formName}</h3>
      </center>
      {droppedItems.length === 0 ? (
        <Card className="empty-drop-zone-card">
          <Text type="secondary" className="empty-drop-zone-card-text">
            <span className="empty-drop-zone-card-icon">
              <IoIosMove />
            </span>
            Drag & drop form fields from the left menu
          </Text>
        </Card>
      ) : (
        <form className="drop-zone-form">
          {droppedItems.map((item, index) => renderField(item, index))}
          <center>
            <Button
              type="default"
              variant="outlined"
              className="dropzone-form-cancel-button"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              icon={<LuEye />}
              className="dropzone-form-preview-button"
              onClick={() => setIsPreviewModal(true)}
            >
              Preview
            </Button>
            <Button
              icon={<FaSave />}
              type="primary"
              className="dropzone-form-save-button"
              onClick={onSave}
              loading={loading}
            >
              Save
            </Button>
          </center>
        </form>
      )}
      <FormPreview
        open={isPreviewModal}
        title={`Previewing ${formName}`}
        formLogo={formLogo}
        onClose={() => setIsPreviewModal(false)}
        droppedItems={droppedItems}
      />
    </div>
  );
};

export default DropZone;
