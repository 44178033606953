import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDrag } from "react-dnd";
import {
  Input,
  // Select,
  Checkbox,
  Button,
  Form,
  Row,
  Col,
} from "antd";
import { debounce } from "lodash";
import {
  IoMailOpen,
  IoCheckbox,
  // IoTime,
  IoColorPaletteOutline,
} from "react-icons/io5";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  IoIosArrowDropdownCircle,
  // IoMdRadioButtonOn
} from "react-icons/io";
import { FaParagraph } from "react-icons/fa";
import { BsCalendar2DateFill } from "react-icons/bs";
import { PiTextboxThin, PiUploadFill } from "react-icons/pi";
import { RxSwitch } from "react-icons/rx";
import { CiSearch } from "react-icons/ci";
// import { RiCheckboxMultipleFill } from "react-icons/ri";

import "./style.css";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../store/store";
import { FormData, getForm } from "../../../store/slices/formListSlice";
import DropZone from "./DropZone";

// const { Option } = Select;

export interface DroppedItem {
  id: string;
  type: string;
  label: string;
  inputPlaceholder?: string;
  inputTitle: string;
  isRequired: boolean;
  maxLength?: number;
  minLength?: number;
  inputType?: "text" | "email" | "number";
  inputMethod: string;
  default?: string;
  options?: {
    label: string | number;
    value: string | number;
  }[];
}

interface DraggableItemProps {
  type: string;
  label: string;
  style?: React.CSSProperties;
  className?: string;
}

const draggableItems = [
  {
    icon: <PiTextboxThin className="draggable-item-icon" />,
    type: "text",
    label: "Text Input",
  },
  {
    icon: <IoMailOpen className="draggable-item-icon" />,
    type: "email",
    label: "Email",
  },
  {
    icon: <FaParagraph className="draggable-item-icon" />,
    type: "textarea",
    label: "Text Area",
  },
  {
    icon: <BsCalendar2DateFill className="draggable-item-icon" />,
    type: "datepicker",
    label: "Date Picker",
  },
  {
    icon: <IoColorPaletteOutline className="draggable-item-icon" />,
    type: "color",
    label: "Color Picker",
  },
  {
    icon: <IoIosArrowDropdownCircle className="draggable-item-icon" />,
    type: "select",
    label: "Dropdown",
  },
  {
    icon: <PiTextboxThin className="draggable-item-icon" />,
    type: "number",
    label: "Input Number",
  },
  {
    icon: <RxSwitch className="draggable-item-icon" />,
    type: "switch",
    label: "Switch",
  },
  {
    icon: <IoCheckbox className="draggable-item-icon" />,
    type: "checkbox",
    label: "Checkbox",
  },
  {
    icon: <PiUploadFill className="draggable-item-icon" />,
    type: "uploadFile",
    label: "Upload File",
  },
  // { icon: <IoMdRadioButtonOn className="draggable-item-icon" />, type: 'radio', label: 'Radio' },
  // { icon: <IoTime className="draggable-item-icon" />, type: 'timePicker', label: 'Time Picker' },
  // { icon: <RiCheckboxMultipleFill className="draggable-item-icon" />, type: 'checkboxGroup', label: 'Checkbox Group' },
];

const DraggableItem = ({ type, label }: DraggableItemProps) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type,
    item: {
      type,
      inputTitle: label,
      options: type === "select" ? [{ label: "", value: "" }] : undefined,
      inputPlaceholder: type === "uploadFile" ? "Choose File" : undefined,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      className="draggable-item-label"
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      {label}
    </div>
  );
};

const FormBuilder = ({ id }: { id: string }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [validationForm] = Form.useForm();
  const [droppedItems, setDroppedItems] = useState<DroppedItem[]>([]);
  const [selectedColumn, setSelectedColumn] = useState<string | null>(
    "VALIDATION COLUMN"
  );
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [form, setForm] = useState<FormData | null>(null);
  const [search, setSearch] = useState("");

  const addOption = () => {
    setDroppedItems((prevItems) => {
      const updatedItems = [...prevItems];
      const itemIndex = updatedItems.findIndex((e) => e.id === selectedItemId);

      if (itemIndex !== -1) {
        updatedItems[itemIndex] = {
          ...updatedItems[itemIndex],
          options: [
            ...(updatedItems[itemIndex]?.options ?? []),
            { label: "", value: "" },
          ],
        };
      }

      return updatedItems;
    });
  };

  const removeOption = (index: number) => {
    setDroppedItems((prevItems) => {
      const updatedItems = [...prevItems];
      const itemIndex = updatedItems.findIndex((e) => e.id === selectedItemId);

      if (itemIndex !== -1) {
        const updatedOptions = (updatedItems[itemIndex]?.options ?? []).filter(
          (_, idx) => idx !== index
        );
        updatedItems[itemIndex] = {
          ...updatedItems[itemIndex],
          options: updatedOptions,
        };
      }

      return updatedItems;
    });
  };

  const updateOption = (
    index: number,
    field: "label" | "value",
    value: string
  ) => {
    setDroppedItems((prevItems) => {
      const newItems = [...prevItems];
      const selectedItemIndex = newItems.findIndex(
        (item) => item.id === selectedItemId
      );
      if (selectedItemIndex !== -1) {
        const updatedOptions = [
          ...(newItems[selectedItemIndex]?.options ?? []),
        ];
        if (updatedOptions[index][field] !== value) {
          updatedOptions[index][field] = value;
          newItems[selectedItemIndex].options = updatedOptions;
        }
      }
      return newItems;
    });
  };

  const getFormData = async () => {
    const response: any = await dispatch(getForm(id));
    if (response.type.includes("fulfilled") && response.payload?.data) {
      const form = response.payload?.data as FormData;
      setForm(form);
      setDroppedItems(
        (form?.formBuilderData ?? [])?.map((item) => {
          const id = item.id ?? Math.random().toString(36).substr(2, 9);
          return {
            ...item,
            id,
          };
        })
      );
    } else {
      navigate("/forms", { replace: true });
    }
  };

  useEffect(() => {
    if (!id) return;
    getFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleColumnSelect = (column: string) => {
    setSelectedColumn(column);
  };

  const handleDrop = (item: DroppedItem) => {
    const newItem = { ...item, id: Math.random().toString(36).substr(2, 9) };
    setDroppedItems((prevItems) => {
      const newItems = [...prevItems, newItem];

      if (newItems.length === 1) {
        setSelectedItemId(newItem?.id);
      }

      return newItems;
    });
  };

  const handleDelete = (id: string) => {
    setDroppedItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  const moveItemUp = (id: string) => {
    const index = droppedItems.findIndex((item) => item.id === id);
    if (index > 0) {
      const newItems = [...droppedItems];
      [newItems[index], newItems[index - 1]] = [
        newItems[index - 1],
        newItems[index],
      ];
      setDroppedItems(newItems);
    }
  };

  const moveItemDown = (id: string) => {
    const index = droppedItems.findIndex((item) => item.id === id);
    if (index < droppedItems.length - 1) {
      const newItems = [...droppedItems];
      [newItems[index], newItems[index + 1]] = [
        newItems[index + 1],
        newItems[index],
      ];
      setDroppedItems(newItems);
    }
  };

  const selectedItem = useMemo(() => {
    const item = droppedItems.find((i) => i.id === selectedItemId);
    if (!item && selectedItemId) setSelectedItemId(null);
    return item;
  }, [droppedItems, selectedItemId]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateField = useCallback(
    debounce((field: string, value: string | boolean) => {
      const dItems = [...droppedItems];
      const i = dItems.findIndex((e) => e.id === selectedItemId);
      if (i !== -1) {
        dItems[i] = {
          ...dItems[i],
          [field]: value,
        };
        setDroppedItems(dItems);
      }
    }, 200),
    [droppedItems, selectedItemId]
  );

  useEffect(() => {
    if (selectedItem) {
      validationForm.setFieldsValue({
        inputTitle: selectedItem?.inputTitle,
        inputPlaceholder: selectedItem?.inputPlaceholder,
        isRequired: selectedItem?.isRequired,
        maxLength: selectedItem?.maxLength,
        minLength: selectedItem?.minLength,
      });
      return;
    }
    validationForm.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, validationForm]);

  const selectOptions = useMemo(() => {
    return selectedItem?.options ?? [];
  }, [selectedItem]);

  const filteredItems = useMemo(() => {
    return draggableItems.filter((item) =>
      item.label.toLowerCase().includes(search.toLowerCase())
    );
  }, [search]);

  return (
    <>
      <div className="form-builder-container">
        {/* First Section: Form Elements */}
        <div className="form-builder-first-section-container">
          <h3 className="form-builder-first-section-title">Form Elements</h3>
          <center className="from-builder-first-section-center">
            <Input
              prefix={
                <CiSearch className="form-builder-first-section-search-icon" />
              }
              placeholder={"Search"}
              className="input-field form-builder-first-section-search-input"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </center>
          <div className="form-builder-child">
            {filteredItems.map((item, index) => (
              <div key={index} className="draggable-item-container">
                {item.icon}
                <DraggableItem type={item.type} label={item.label} />
              </div>
            ))}
          </div>
        </div>

        <div className="dropZone-parent">
          <DropZone
            formId={id}
            formName={form?.formTitle ?? ""}
            formLogo={form?.formLogo ?? ""}
            onDrop={handleDrop}
            droppedItems={droppedItems}
            onDelete={handleDelete}
            onMoveUp={moveItemUp}
            onMoveDown={moveItemDown}
            selectedItemId={selectedItemId}
            setSelectedItemId={setSelectedItemId}
          />
        </div>

        {selectedItemId && (
          <div className="third-section-container">
            <div className="third-section-heading-container">
              <div
                onClick={() => handleColumnSelect("VALIDATION COLUMN")}
                className="form-builder-third-section-heading"
                style={{
                  borderBottom:
                    selectedColumn === "VALIDATION COLUMN"
                      ? "2px solid blue"
                      : "1px solid #ccc",
                  color:
                    selectedColumn === "VALIDATION COLUMN" ? "#3b63f3" : "",
                }}
              >
                VALIDATION
              </div>
              <div
                onClick={() => handleColumnSelect("LOGIC COLUMN")}
                className="form-builder-third-section-heading"
                style={{
                  borderBottom:
                    selectedColumn === "LOGIC COLUMN"
                      ? "2px solid blue"
                      : "1px solid #ccc",
                  color: selectedColumn === "LOGIC COLUMN" ? "#3b63f3" : "",
                }}
              >
                LOGIC
              </div>
            </div>

            <div className="third-section-content-container">
              <div
                className="third-section-validation-content-container"
                style={{
                  display:
                    selectedColumn === "VALIDATION COLUMN" ? "block" : "none",
                }}
              >
                <Form
                  form={validationForm}
                  layout="vertical"
                  className="third-section-form"
                >
                  <Form.Item label="Input Title" name="inputTitle">
                    <Input
                      value={selectedItem?.inputTitle}
                      onChange={(e) =>
                        updateField("inputTitle", e.target.value)
                      }
                      className="input-field"
                    />
                  </Form.Item>
                  {selectedItem?.type !== "datepicker" &&
                    selectedItem?.type !== "color" &&
                    selectedItem?.type !== "switch" &&
                    selectedItem?.type !== "checkbox" && (
                      <Form.Item
                        label="Input Placeholder"
                        name="inputPlaceholder"
                      >
                        <Input
                          value={selectedItem?.inputPlaceholder}
                          onChange={(e) =>
                            updateField("inputPlaceholder", e.target.value)
                          }
                          className="input-field"
                        />
                      </Form.Item>
                    )}

                  <div className="third-section-validation-checkbox-container">
                    <Checkbox
                      checked={selectedItem?.isRequired}
                      onChange={(e) => {
                        updateField("isRequired", e.target.checked);
                      }}
                      className="third-section-validation-checkbox"
                    >
                      Required
                    </Checkbox>
                    {/* <Checkbox className="third-section-validation-checkbox">
                      Unique
                    </Checkbox> */}
                    {/* <Checkbox className="third-section-validation-checkbox">
                        Validate When Hidden
                      </Checkbox> */}
                  </div>
                  <br />
                  {selectedItem?.type !== "datepicker" &&
                    selectedItem?.type !== "color" &&
                    selectedItem?.type !== "select" &&
                    selectedItem?.type !== "switch" &&
                    selectedItem?.type !== "checkbox" &&
                    selectedItem?.type !== "uploadFile" && (
                      <>
                        <Form.Item label="Minimum Length" name="minLength">
                          <Input
                            value={selectedItem?.minLength}
                            onChange={(e) =>
                              updateField("minLength", e.target.value)
                            }
                            type="number"
                            className="input-field"
                          />
                        </Form.Item>
                        <Form.Item label="Maximum Length" name="maxLength">
                          <Input
                            value={selectedItem?.maxLength}
                            onChange={(e) =>
                              updateField("maxLength", e.target.value)
                            }
                            type="number"
                            className="input-field"
                          />
                        </Form.Item>
                      </>
                    )}

                  {selectedItem?.type === "select" && (
                    <Form.Item label="Options" name="options">
                      {selectOptions?.map((option: any, index: number) => (
                        <Row
                          key={index}
                          gutter={8}
                          align="middle"
                          style={{ marginBottom: 8 }}
                        >
                          <Col span={10}>
                            <Input
                              value={option.label}
                              onChange={(e) =>
                                updateOption(index, "label", e.target.value)
                              }
                              placeholder="Label"
                              className="input-field"
                            />
                          </Col>
                          <Col span={10}>
                            <Input
                              value={option.value}
                              onChange={(e) =>
                                updateOption(index, "value", e.target.value)
                              }
                              placeholder="Value"
                              className="input-field"
                            />
                          </Col>
                          <Col span={2}>
                            {index === selectOptions?.length - 1 ? (
                              <Button
                                onClick={addOption}
                                icon={<PlusOutlined />}
                              />
                            ) : (
                              <Button
                                onClick={() => removeOption(index)}
                                icon={<DeleteOutlined />}
                              />
                            )}
                          </Col>
                        </Row>
                      ))}
                    </Form.Item>
                  )}
                  {/* <Form.Item label="Error Label" name="errorLabel">
                      <Input className="input-field" />
                    </Form.Item> */}

                  {/* <Form.Item
                      label="Custom Error Message"
                      name="customErrorMessage"
                    >
                      <Input className="input-field" />
                    </Form.Item>
                    <Button block>Custom Validation</Button>
                    <br />
                    <br />
                    <Button block>Json Validation</Button> */}
                </Form>
              </div>

              <div
                className="third-section-validation-content-container"
                style={{
                  display: selectedColumn === "LOGIC COLUMN" ? "block" : "none",
                }}
              >
                {/* <Form layout="vertical" className="third-section-form">
                  <Form.Item label="Advanced Logic" name="logicName">
                    <Input placeholder="Logic Name" className="input-field" />
                  </Form.Item>

                  <h4>Trigger</h4>
                  <Form.Item label="Type" name="type">
                    <Select defaultValue="Simple" className="input-field">
                      <Option value="Simple">Simple</Option>
                      <Option value="Advanced">Advanced</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item label="When" name="when">
                    <Select
                      placeholder="Select condition"
                      className="input-field"
                    >
                      <Option value="condition1">Condition 1</Option>
                      <Option value="condition2">Condition 2</Option>
                    </Select>
                  </Form.Item>

                  <h4>Conditions</h4>
                  <Form.Item label="When" name="conditionWhen">
                    <Input placeholder="When" className="input-field" />
                  </Form.Item>

                  <Form.Item label="Is" name="conditionIs">
                    <Input placeholder="Is" className="input-field" />
                  </Form.Item>

                  <Form.Item label="Value" name="conditionValue">
                    <Input placeholder="Value" className="input-field" />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="default"
                      className="third-section-logic-cancel-button"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      className="third-section-logic-save-button"
                    >
                      Save
                    </Button>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      color="primary"
                      variant="outlined"
                      block
                      icon={"+"}
                      className="third-section-logic-add-button"
                    >
                      Add Logic
                    </Button>
                  </Form.Item>
                </Form> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FormBuilder;
