import React from "react";
import { Button, Layout } from "antd";
import { LuPlusCircle } from "react-icons/lu";
import EmptyList from "../../../assets/images/empty-list.png";
import { useNavigate } from "react-router-dom";
import './style.css';

const { Content } = Layout;

const EmptyFormList: React.FC = () => {
  const navigate = useNavigate();

  const onCreateForm = () => {
    navigate("/create-new-form");
  };

  return (
    <Content className="empty-content-section-container">
      <div className="empty-content-inner">
        <div>
          <img
            src={EmptyList}
            alt="EmptyList"
            className="empty-list-image"
          />
        </div>

        <div className="empty-content-text">
          You don’t have any forms yet
        </div>

        <div>
          <Button
            type="primary"
            className="create-form-button"
            icon={<LuPlusCircle />}
            size="large"
            onClick={onCreateForm}
          >
            Create Form
          </Button>
        </div>
      </div>
    </Content>
  );
};

export default EmptyFormList;
