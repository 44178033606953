import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/Login";
import ForgetPassword from "./components/ForgetPassword";
import FormBuilderComponent from "./components/CreateForm";
import LayoutComponent from "./components/Layout";
import CreateNewForm from "./components/Forms/CreateNewForm/CreateNewForm";
import Forms from "./components/Forms/Index";
import FormLists from "./components/Forms/FormLists/Index";
import { useAppSelector } from "./store/store";
import PrivateRoute from "./components/PrivateRoute";
import ResetPassword from "./components/ResetPassword";
import NotFound from "./components/NotFound";
import ComingSoon from "./components/ComingSoon";

function App() {
  const { token } = useAppSelector((state) => state.auth);

  return (
    <BrowserRouter>
      <Routes>
        {/* If logged in, show protected routes */}
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<LayoutComponent />}>
            <Route index path="forms" element={<Forms />} />
            <Route path="reports" element={<ComingSoon />} />
            <Route path="users" element={<ComingSoon />} />
            <Route path="settings" element={<ComingSoon />} />
            <Route path="create-new-form" element={<CreateNewForm />} />
            <Route path="list" element={<FormLists />} />
            <Route path="/" element={<Navigate to="/forms" />} />
          </Route>
          <Route path="form-builder/:id" element={<FormBuilderComponent />} />
          <Route path="form-builder" element={<Navigate to="/forms" />} />
        </Route>

        {/* Unauthenticated routes */}
        {!token && (
          <>
            <Route index path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgetPassword />} />
            <Route path="resetPassword" element={<ResetPassword />} />
          </>
        )}

        {/* If logged in, redirect to forms if trying to access login or forgot password */}
        {token && (
          <>
            <Route path="login" element={<Navigate to="/forms" />} />
            <Route path="forgot-password" element={<Navigate to="/forms" />} />
            <Route path="resetPassword" element={<Navigate to="/forms" />} />
          </>
        )}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
