import React from "react";
import logo1 from '../../assets/images/companylogo.png'
import "./style.css";

interface AuthWrapperProps {
  title: string;
  children: React.ReactNode;
}

const AuthWrapper = ({ title, children }: AuthWrapperProps) => {
  return (
    <div className="auth_container">
      <div className="auth_content">
        <img src={logo1} alt="logo here" height={80} width={160} />
        <h1 className="auth_title">{title}</h1>
        {children}
      </div>
    </div>
  );
};

export default AuthWrapper;
