import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the initial state for user data
export interface User {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  roleId: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

interface UserState {
  user: User | null;
}

const initialState: UserState = {
  user: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
    },
  },
});

// Export the actions to dispatch
export const { setUser, clearUser } = userSlice.actions;

// Export the reducer to be added to the store
export default userSlice.reducer;
