import React, { useEffect, useRef, useState } from "react";
import { Table, Button, Switch, Input, Modal, message } from "antd";
import "./style.css";
import type { TableColumnsType } from "antd";
import { CiSearch } from "react-icons/ci";
import {
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import EmptyFormList from "./EmptyFormList";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  fetchFormData,
  setPage,
  setPageSize,
  FormData,
  deleteForm,
  updateForm,
} from "../../../store/slices/formListSlice";
import { debounce } from "lodash";

const FormLists: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data, loading, currentPage, pageSize, total } = useAppSelector(
    (state) => state.formList
  );
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const searchValRef = useRef("");
  const [isTableVisible, setIsTableVisible] = useState<boolean>(false);

  const onAddNewForm = () => {
    navigate("/create-new-form");
  };

  const handleStatusChange = async (
    id: string,
    status: "active" | "inactive"
  ) => {
    setDeleteLoading(true);

    try {
      const response: any = await dispatch(updateForm({ id, status }));
      if (
        response.type.includes("fulfilled") &&
        response.payload?.status === "success"
      ) {
        dispatch(fetchFormData({ page: currentPage, limit: pageSize, search }));
        message.success("Form status updated successfully!");
      } else {
        throw new Error();
      }
    } catch (error) {
      message.error("Failed to update form.");
    } finally {
      setDeleteLoading(false);
    }
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    dispatch(setPage(page));
    dispatch(setPageSize(pageSize));
  };

  const onEdit = (id: string) => {
    navigate(`/form-builder/${id}`);
  };

  const onDownload = (record: FormData) => {
    const jsonData = JSON.stringify(record, null, 2);

    const blob = new Blob([jsonData], { type: "application/json" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${record._id}.json`;
    link.click();
    URL.revokeObjectURL(link.href);
  };

  const onDelete = (id: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      setDeleteLoading(true);

      dispatch(deleteForm(id))
        .then((response: any) => {
          if (
            response.type.includes("fulfilled") &&
            response.payload?.status === "success"
          ) {
            dispatch(
              fetchFormData({ page: currentPage, limit: pageSize, search })
            );
            message.success("Form deleted successfully!");
            resolve();
          } else {
            reject(new Error("Deletion failed"));
          }
        })
        .catch((error) => {
          message.error("Failed to delete form.");
          reject(error);
        })
        .finally(() => {
          setDeleteLoading(false);
        });
    });
  };

  const columns: TableColumnsType<FormData> = [
    // {
    //   title: "#",
    //   dataIndex: "_id",
    //   render: (_, record) => (
    //     <Checkbox type="checkbox" className="formlist-checkbox" />
    //   ),
    // },
    {
      title: "Title",
      dataIndex: "formTitle",
      render: (text) => (
        <span>
          <b>{text}</b>
        </span>
      ),
      width: "35vw",
      className: "table-title",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status: string, record: FormData) => (
        <Switch
          checked={status === "active"}
          onChange={() =>
            handleStatusChange(
              record._id,
              status === "active" ? "inactive" : "active"
            )
          }
          style={{
            backgroundColor: status === "active" ? "#10b981" : "#e5e7eb",
          }}
        />
      ),
    },
    {
      title: "Actions",
      render: (_, record) => (
        <>
          <Button
            onClick={() => onEdit(record._id)}
            icon={<EditOutlined />}
            className="formlist-button"
          >
            Edit
          </Button>
          <Button
            onClick={() => onDownload(record)}
            icon={<DownloadOutlined />}
            className="formlist-button"
          >
            Download JSON
          </Button>
          <Button
            icon={<DeleteOutlined />}
            danger
            className="formlist-delete-button"
            onClick={async () => {
              Modal.confirm({
                title: "Delete Form",
                content: <>Are you sure you want to delete this form?</>,
                onOk: () => onDelete(record._id),
                okButtonProps: { loading: deleteLoading },
              });
            }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  const components = {
    header: {
      cell: (props: any) => (
        <th
          {...props}
          style={{
            backgroundColor: "transparent",
            color: "black",
            fontWeight: "normal",
          }}
        />
      ),
    },
  };

  useEffect(() => {
    dispatch(
      fetchFormData({
        page: currentPage,
        limit: pageSize,
        search: searchValRef.current,
      })
    );
  }, [currentPage, pageSize, dispatch]);

  const fetchForms = (value: string) => {
    dispatch(
      fetchFormData({ page: currentPage, limit: pageSize, search: value })
    );
  };

  const debouncedFetchForms = debounce(fetchForms, 1000);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    searchValRef.current = value;
    setSearch(value);
    debouncedFetchForms(value);
  };

  useEffect(() => {
    if (isTableVisible) return;
    if (data.length > 0) setIsTableVisible(true);
  }, [isTableVisible, data]);

  return isTableVisible ? (
    <div className="formlist-container">
      <div className="formlist-child-container">
        <h2 className="formlist-title">Forms</h2>
        <div className="formlist-search-container">
          <Input
            placeholder="Search Form"
            prefix={<CiSearch className="formlist-search-icon" />}
            className="form-search-input"
            value={search}
            onChange={handleSearchChange}
          />
          <Button
            onClick={onAddNewForm}
            icon={<PlusOutlined />}
            type="primary"
            className="formlist-add-button"
          >
            Add New Form
          </Button>
        </div>
      </div>
      <Table
        components={components}
        rowClassName="table-row"
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total,
          onChange: handlePaginationChange,
        }}
      />
    </div>
  ) : !loading ? (
    <EmptyFormList />
  ) : null;
};

export default FormLists;
