import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // default is localStorage
import authReducer from "./slices/authSlice";
import userReducer from "./slices/userSlice";
import formListReducer from "./slices/formListSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

// Define a persist config object for the auth and user slices
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "user"], // Only persist auth and user slices
};

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  formList: formListReducer,
});

// Wrap the reducers with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  // Enable Redux DevTools (optional)
  devTools: process.env.NODE_ENV !== "production",
});

// Create a persistor to manage the persisted store
export const persistor = persistStore(store);

// Export types for state and dispatch
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
