import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
import { Layout, Menu } from "antd";
import { BsHddStack } from "react-icons/bs";
import { FaRegFileAlt } from "react-icons/fa";
import { LuUser2 } from "react-icons/lu";
import { IoSettingsOutline } from "react-icons/io5";
const { Sider } = Layout;

const SideBar: React.FC = () => {
  return (
    <Sider width={259} className="sidebar-container">
      <Menu
        mode="vertical"
        defaultSelectedKeys={["1"]}
        className="sidebar-menu"
      >
        <Menu.Item key="1" icon={<BsHddStack className="sidebar-menu-icon" />}>
          <Link to="/forms">Forms</Link>
        </Menu.Item>
        <Menu.Item
          key="2"
          icon={<FaRegFileAlt className="sidebar-reports-menu-icon" />}
        >
          <Link to="/users">Reports</Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<LuUser2 className="sidebar-menu-icon" />}>
          <Link to="/reports">Users</Link>
        </Menu.Item>
        <Menu.Item
          key="4"
          icon={<IoSettingsOutline className="sidebar-menu-icon" />}
        >
          <Link to="/settings">Settings</Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default SideBar;
