import React, { useEffect, useState } from "react";
import "./style.css";
import {
  Tabs,
  Form,
  Input,
  Button,
  // Upload,
  message,
  Checkbox,
  Modal,
  Upload,
} from "antd";
import { List, Typography } from "antd";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import {
  createForm,
  fetchFormTemplateData,
  FormData as FormDataType,
} from "../../../store/slices/formListSlice";
import { useAppDispatch } from "../../../store/store";
import { debounce } from "lodash";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import { post } from "../../../api/apiClient";

const { TabPane } = Tabs;

const CreateNewForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState("create");
  const [loading, setLoading] = useState<boolean>(false);
  const [isUseTemplateModal, setIsUseTemplateModal] = useState<boolean>(false);
  const [templateLoading, setTemplateLoading] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const [templates, setTemplates] = useState<FormDataType[]>([]);
  const [selectedItem, setSelectedItem] = useState<FormDataType | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);

  const handleChange = async ({
    file,
    onSuccess,
    onError,
  }: any): Promise<void> => {
    const formData = new FormData();
    formData.append("image", file);

    setUploading(true);

    try {
      // Upload the image manually to your server using fetch or axios
      const response = await post("/form-builder/upload-image", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Required for uploading files
        },
      });

      const result: any = response.data;

      if (result?.fileName) {
        // Successfully uploaded, set image URL
        setImageUrl(result.fileName);
        message.success("Image uploaded successfully");
      } else {
        message.error("Failed to retrieve image URL");
      }

      onSuccess(); // Notify Ant Design Upload that the upload was successful
    } catch (error) {
      message.error(
        "Error uploading image: " +
          (error instanceof Error ? error.message : "")
      );
      onError(error); // Notify Ant Design Upload about the error
    } finally {
      setUploading(false);
    }
  };

  const beforeUpload = (file: RcFile) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      message.error("You can only upload image files!");
    }
    return isImage;
  };

  const fetchFormTemplate = async (value?: string) => {
    setTemplateLoading(true);

    try {
      const response: any = await dispatch(fetchFormTemplateData(value));
      setTemplates(response?.payload?.data ?? []);
    } catch (error) {
      console.log(error);
    } finally {
      setTemplateLoading(false);
    }
  };

  const debouncedFetchFormTemplate = debounce(fetchFormTemplate, 1000);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
    debouncedFetchFormTemplate(value);
  };

  useEffect(() => {
    fetchFormTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreate = async (values: {
    title: string;
    description: string;
    useAsTemplate: boolean;
  }) => {
    setLoading(true);

    try {
      const response: any = await dispatch(
        createForm({
          ...values,
          image: imageUrl ?? "",
          formBuilderData: selectedItem?.formBuilderData
            ? selectedItem?.formBuilderData
            : undefined,
        })
      );
      if (
        response.type.includes("fulfilled") &&
        response.payload?.status === "success"
      ) {
        message.success("Form created successfully!");
        navigate(`/form-builder/${response.payload?.data?._id}`);
      } else {
        message.error(response.payload?.message ?? "Failed to create form.");
      }
    } catch (error) {
      message.error("Failed to create form.");
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (key: string) => {
    setActiveKey(key);
  };

  const handleSelect = (item: FormDataType) => {
    setSelectedItem(item);
  };

  return (
    <>
      <center className="full-height">
        <div className="content-section-container">
          <Tabs
            defaultActiveKey="create"
            onChange={handleTabChange}
            className="tab-style"
            centered
          >
            <TabPane
              tab={<span className="tab-text">CREATE NEW FORM</span>}
              key="create"
            />
            <TabPane
              tab={<span className="tab-text">USE TEMPLATES</span>}
              key="templates"
            />
          </Tabs>

          {activeKey === "create" ? (
            <Form layout="vertical" onFinish={onCreate} className="form-style">
              <Form.Item
                label="Form Title"
                name="title"
                rules={[
                  { required: true, message: "Please enter a form title" },
                  {
                    max: 100,
                    message: "Title cannot be more than 100 characters",
                  },
                ]}
              >
                <Input placeholder="Enter form title" className="form-input" />
              </Form.Item>

              <Form.Item
                label="Description"
                name="description"
                rules={[
                  { required: true, message: "Please enter a description" },
                  {
                    max: 500,
                    message: "Description cannot be more than 500 characters",
                  },
                ]}
              >
                <Input.TextArea placeholder="Enter description" rows={5} />
              </Form.Item>

              <Form.Item
                className="file-uploader"
                label="Upload Image"
                name="image"
                rules={[{ required: true, message: "Please upload an image!" }]}
              >
                <Upload
                  name="image"
                  accept="image/*"
                  beforeUpload={beforeUpload}
                  maxCount={1}
                  customRequest={handleChange}
                  showUploadList={false}
                >
                  <Button icon={<UploadOutlined />}>
                    {uploading
                      ? "Uploading..."
                      : imageUrl
                      ? imageUrl
                      : "Click to Upload Image"}
                  </Button>
                </Upload>
              </Form.Item>

              <Form.Item
                className="use-as-template-checkbox"
                name="useAsTemplate"
                valuePropName="checked"
              >
                <Checkbox className="third-section-validation-checkbox">
                  Use as Template
                </Checkbox>
              </Form.Item>

              <center>
                <Button
                  onClick={() => navigate("/forms", { replace: true })}
                  className="form-cancel-button"
                >
                  Cancel
                </Button>
                <Button
                  className="form-submit-button"
                  loading={loading}
                  htmlType="submit"
                >
                  Create
                </Button>
              </center>
            </Form>
          ) : (
            <div className="template-section-container">
              <div className="template-search-container">
                <Input
                  prefix={<CiSearch className="template-search-icon" />}
                  placeholder="Search"
                  className="template-search-input"
                  value={search}
                  onChange={handleSearchChange}
                />

                {/* <Upload name="logo" action="/upload.do" accept=".json,.xml">
                  <Button icon={"+"} className="form-upload-button">
                    Upload File
                  </Button>
                </Upload> */}
              </div>

              <div className="template-list-container">
                <List
                  loading={templateLoading}
                  dataSource={templates}
                  renderItem={(item) => (
                    <List.Item
                      onClick={() => handleSelect(item)}
                      className={`template-list-item ${
                        selectedItem === item ? "selected-item" : ""
                      }`}
                    >
                      <Typography.Text
                        className={
                          selectedItem?._id === item?._id ? "selected-text" : ""
                        }
                        strong={selectedItem?._id === item?._id}
                      >
                        {item.formTitle}
                      </Typography.Text>
                    </List.Item>
                  )}
                />
              </div>

              <div className="template-button-container">
                <center>
                  <Button
                    onClick={() => setActiveKey("create")}
                    className="form-cancel-button"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => setIsUseTemplateModal(true)}
                    disabled={!selectedItem}
                    className="form-submit-button"
                  >
                    Use Template
                  </Button>
                </center>
              </div>
            </div>
          )}
        </div>
      </center>
      {isUseTemplateModal && (
        <Modal
          title="Use Template"
          open={isUseTemplateModal}
          onClose={() => setIsUseTemplateModal(false)}
          footer={null}
        >
          <Form
            layout="vertical"
            onFinish={onCreate}
            className="form-style full-width"
            initialValues={{
              title: selectedItem?.formTitle,
              description: selectedItem?.formDescription,
            }}
          >
            <Form.Item
              label="Form Title"
              name="title"
              rules={[
                { required: true, message: "Please enter a form title" },
                {
                  max: 100,
                  message: "Title cannot be more than 100 characters",
                },
              ]}
            >
              <Input placeholder="Enter form title" className="form-input" />
            </Form.Item>

            <Form.Item
              label="Description"
              name="description"
              rules={[
                { required: true, message: "Please enter a description" },
                {
                  max: 500,
                  message: "Description cannot be more than 500 characters",
                },
              ]}
            >
              <Input.TextArea placeholder="Enter description" rows={5} />
            </Form.Item>

            <Form.Item
              className="file-uploader"
              label="Upload Image"
              name="image"
              rules={[{ required: true, message: "Please upload an image!" }]}
            >
              <Upload
                name="image"
                accept="image/*"
                beforeUpload={beforeUpload}
                maxCount={1}
                customRequest={handleChange}
                showUploadList={false}
              >
                <Button icon={<UploadOutlined />}>
                  {uploading
                    ? "Uploading..."
                    : imageUrl
                    ? imageUrl
                    : "Click to Upload Image"}
                </Button>
              </Upload>
            </Form.Item>

            <center>
              <Button
                onClick={() => setIsUseTemplateModal(false)}
                className="form-cancel-button"
              >
                Cancel
              </Button>
              <Button
                className="form-submit-button"
                loading={loading}
                htmlType="submit"
              >
                Create
              </Button>
            </center>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default CreateNewForm;
