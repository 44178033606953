import React from "react";
import "./style.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import FormBuilder from "./FormBuilder";
import HeaderCmp from "../Layout/Header";
import { useParams } from "react-router-dom";

const FormBuilderComponent: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <HeaderCmp />
      <DndProvider backend={HTML5Backend}>
        {id && <FormBuilder id={id} />}
      </DndProvider>
    </>
  );
};

export default FormBuilderComponent;
