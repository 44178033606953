import React from "react";
import {
  Input,
  DatePicker,
  ColorPicker,
  InputNumber,
  Radio,
  Select,
  Switch,
  TimePicker,
  Checkbox,
  Button,
  Upload,
  Modal,
  Form,
} from "antd";

import "./style.css";
import { DroppedItem } from ".";

const { Option } = Select;
const CheckboxGroup = Checkbox.Group;

const FormItem = ({
  label,
  component,
  className,
}: {
  label: string;
  component: React.ReactNode;
  className?: string;
}) => (
  <div className={className}>
    <label className="form-label">{label}</label>
    {component}
  </div>
);

interface FormPreviewProps {
  open: boolean;
  title: string;
  formLogo: string;
  onClose: () => void;
  droppedItems: DroppedItem[];
}

const FormPreview = ({
  open,
  title,
  formLogo,
  onClose,
  droppedItems,
}: FormPreviewProps) => {
  const [previewForm] = Form.useForm();

  const renderField = (item: DroppedItem, index: number) => {
    const itemStyle: React.CSSProperties = {
      position: "relative",
      borderRadius: "8px",
      transition: "background-color 0.3s ease",
    };

    return (
      <div key={item.id} className="input-field-container" style={itemStyle}>
        {renderComponentByType(item)}
      </div>
    );
  };

  const renderComponentByType = (item: DroppedItem, commonProps?: any) => {
    switch (item.type) {
      case "text":
        return (
          <FormItem
            {...commonProps}
            label={item.inputTitle}
            component={
              <Input
                maxLength={item?.maxLength}
                minLength={item?.minLength}
                placeholder={item.inputPlaceholder}
              />
            }
          />
        );
      case "email":
        return (
          <FormItem
            {...commonProps}
            label={item.inputTitle}
            component={
              <Input
                maxLength={item?.maxLength}
                minLength={item?.minLength}
                placeholder={item.inputPlaceholder}
                type="email"
              />
            }
          />
        );
      case "textarea":
        return (
          <FormItem
            {...commonProps}
            label={item.inputTitle}
            component={
              <Input.TextArea
                maxLength={item?.maxLength}
                minLength={item?.minLength}
                placeholder={item.inputPlaceholder}
              />
            }
          />
        );
      case "datepicker":
        return (
          <FormItem
            {...commonProps}
            label={item.inputTitle}
            component={<DatePicker className="form-item" />}
          />
        );
      case "color":
        return (
          <FormItem
            {...commonProps}
            label={item.inputTitle}
            component={<ColorPicker />}
          />
        );
      case "select":
        return (
          <FormItem
            {...commonProps}
            label={item.inputTitle}
            component={
              <Select placeholder={item.inputPlaceholder} className="form-item">
                {item?.options?.map((e) => (
                  <Option value={e.value}>{e.label}</Option>
                ))}
              </Select>
            }
          />
        );
      case "number":
        return (
          <FormItem
            {...commonProps}
            label={item.inputTitle}
            component={
              <InputNumber
                type="number"
                placeholder={item.inputPlaceholder}
                className="form-item"
              />
            }
          />
        );
      case "radio":
        return (
          <FormItem
            {...commonProps}
            label={item.inputTitle}
            component={
              <Radio.Group>
                <Radio value={1}>A</Radio>
                <Radio value={2}>B</Radio>
                <Radio value={3}>C</Radio>
                <Radio value={4}>D</Radio>
              </Radio.Group>
            }
          />
        );
      case "switch":
        return (
          <FormItem
            {...commonProps}
            label={item.inputTitle}
            component={<Switch />}
          />
        );
      case "timePicker":
        return (
          <FormItem
            {...commonProps}
            label={item.inputTitle}
            component={<TimePicker className="form-item" />}
          />
        );
      case "checkbox":
        return (
          <FormItem
            {...commonProps}
            label={item.inputTitle}
            component={<Checkbox>{item.label}</Checkbox>}
          />
        );
      case "checkboxGroup":
        return (
          <FormItem
            {...commonProps}
            label={item.inputTitle}
            component={<CheckboxGroup options={item?.options ?? []} />}
          />
        );
      case "uploadFile":
        return (
          <FormItem
            {...commonProps}
            label={item.inputTitle}
            component={
              <Upload>
                <Button>{item.inputPlaceholder}</Button>
              </Upload>
            }
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      onCancel={onClose}
      title={title}
      destroyOnClose
      footer={null}
      style={{
        height: "100vh",
        width: "100vw",
        margin: 0,
        top: 0,
      }}
      styles={{
        body: {
          height: "calc(100% - 20px)",
          borderTop: "1px solid rgba(5, 5, 5, 0.06)",
          marginTop: "15px",
        },
        content: {
          height: "100vh",
          width: "100vw",
          margin: 0,
          top: 0,
        },
      }}
    >
      <Form
        form={previewForm}
        name="validateOnly"
        layout="vertical"
        autoComplete="off"
        className="form-preview-container"
      >
        <br />

        <img
          src={formLogo}
          alt="formLogo"
          width="150px"
          height="150px"
          style={{ objectFit: "contain" }}
        />
        {droppedItems.map((item, index) => renderField(item, index))}
        <br />
      </Form>
    </Modal>
  );
};

export default FormPreview;
