import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";
import AuthWrapper from "../AuthWrapper";
import { Link } from "react-router-dom";
import "./style.css";
import { handleLoginThunkAsync } from "../../store/slices/authSlice";
import { useAppDispatch } from "../../store/store";

const Login: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: { Email: string; Password: string }) => {
    const { Email, Password } = values;

    setLoading(true);

    try {
      // Dispatch the login action
      await dispatch(
        handleLoginThunkAsync({ email: Email, password: Password })
      );
    } catch (err) {
      message.error("Login failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthWrapper title="Sign In">
      <Form
        form={form}
        name="validateOnly"
        layout="vertical"
        autoComplete="off"
        className="auth_form_container"
        onFinish={onFinish}
      >
        <Form.Item
          name="Email"
          label="Email"
          rules={[
            { required: true, message: "Please enter your email!" },
            { type: "email", message: "Please enter a valid email!" },
          ]}
        >
          <Input className="form-input" />
        </Form.Item>
        <Form.Item
          name="Password"
          label="Password"
          rules={[
            { required: true, message: "Please enter your password!" },
            { min: 6, message: "Password must be at least 6 characters!" },
          ]}
        >
          <Input.Password className="form-input" />
        </Form.Item>
        <Form.Item>
          <Button
            block
            type="primary"
            className="submit_button"
            htmlType="submit"
            loading={loading}
          >
            Sign In
          </Button>
          <br />
          <Link to="/forgot-password" className="navigate_link">
            Forget Your Password?
          </Link>
        </Form.Item>
      </Form>
    </AuthWrapper>
  );
};

export default Login;
