import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";
import AuthWrapper from "../AuthWrapper";
import { Link } from "react-router-dom";
import "./style.css";
import { handleForgotPasswordThunkAsync } from "../../store/slices/authSlice";
import { useAppDispatch } from "../../store/store";

const ForgetPassword: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: { Email: string }) => {
    const { Email } = values;

    setLoading(true);

    try {
      await dispatch(handleForgotPasswordThunkAsync({ email: Email }));
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthWrapper title="Forget Password?">
      <Form
        form={form}
        name="validateOnly"
        layout="vertical"
        autoComplete="off"
        className="auth_form_container"
        onFinish={onFinish}
      >
        <p className="forget_password_text">
          Enter the email address associated with your account and we'll send
          you instructions to reset your password.
        </p>
        <br />
        <Form.Item
          name="Email"
          label="Email"
          rules={[
            { required: true, message: "Please enter your email!" },
            { type: "email", message: "Please enter a valid email!" },
          ]}
        >
          <Input className="form-input" />
        </Form.Item>
        <Form.Item>
          <Button
            block
            type="primary"
            className="submit_button"
            htmlType="submit"
            loading={loading}
          >
            Reset
          </Button>
          <br />
          <br />
          <Link to="/login" className="navigate_link back_to_sign_in">
            Back to Sign In
          </Link>
        </Form.Item>
      </Form>
    </AuthWrapper>
  );
};

export default ForgetPassword;
