import React from "react";
import { Outlet } from "react-router-dom";
import "./style.css";
import { Layout } from "antd";
import HeaderCmp from "./Header";
import SideBar from "./SideBar";

const LayoutCmp: React.FC = () => {
  return (
    <Layout className="layout-parent-container">
      <HeaderCmp />

      <Layout>
        <SideBar />
        <Layout className="layout-container">
          <Outlet />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default LayoutCmp;
