import React, { useEffect, useState } from "react";
import { Button, Form, Input, message } from "antd";
import AuthWrapper from "../AuthWrapper";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";
import { handleResetPasswordThunkAsync } from "../../store/slices/authSlice";
import useQuery from "../../hooks/useQuery";
import { useAppDispatch } from "../../store/store";

const ResetPassword: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const navigate = useNavigate();

  // Extract the reset code from the query parameters
  const resetCode = query.get("token");

  const onFinish = async (values: {
    newPassword: string;
    confirmPassword: string;
  }) => {
    const { newPassword, confirmPassword } = values;

    if (newPassword !== confirmPassword) {
      message.error("Passwords do not match!");
      return;
    }

    setLoading(true);

    try {
      await dispatch(
        handleResetPasswordThunkAsync({
          token: `${resetCode}`,
          password: confirmPassword,
        })
      );
      navigate("/login", { replace: true });
    } catch (err: any) {
      message.error(
        err.message || "An error occurred while resetting your password"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (resetCode) return;
    navigate("/login", { replace: true });
  }, [navigate, resetCode]);

  return (
    <AuthWrapper title="Reset Password">
      <Form
        form={form}
        name="reset_password"
        layout="vertical"
        autoComplete="off"
        className="auth_form_container"
        onFinish={onFinish}
      >
        <p className="forget_password_text">
          Enter your new password below and confirm it to reset your password.
        </p>
        <br />

        <Form.Item
          name="newPassword"
          label="New Password"
          rules={[
            { required: true, message: "Please enter your new password!" },
            { min: 6, message: "Password must be at least 6 characters!" },
          ]}
        >
          <Input.Password className="form-input" />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          label="Confirm Password"
          rules={[
            { required: true, message: "Please confirm your new password!" },
            { min: 6, message: "Password must be at least 6 characters!" },
          ]}
        >
          <Input.Password className="form-input" />
        </Form.Item>

        <Form.Item>
          <Button
            block
            type="primary"
            className="submit_button"
            htmlType="submit"
            loading={loading}
          >
            Reset Password
          </Button>
          <br />
          <br />
          <Link to="/login" className="navigate_link back_to_sign_in">
            Back to Sign In
          </Link>
        </Form.Item>
      </Form>
    </AuthWrapper>
  );
};

export default ResetPassword;
